import * as React from "react";
import intl from "react-intl-universal";
import ButtonGroup from "common/components/partials/inputs/button-group";
import Button from "common/components/partials/inputs/button";
import {
  closeModal,
  openModal,
  setSharedModalContent,
} from "common/shared-modal";

export function openBirthYearModal(onBackClick) {
  const birthYearModalContent = (
    <div className="cope-core--accounts--birth-year-modal">
      <div className="cope-core-text text-headline-2">
        <h2>{intl.get("userInfo.inputs.birthYear.errorModal.heading")}</h2>
      </div>
      <div className="cope-core-text text-body-xl">
        <p>
          {intl.get(
            "userInfo.inputs.birthYear.errorModal.text" +
              getBirthyearThreshold()
          )}
        </p>
      </div>
      <ButtonGroup>
        <Button
          primary
          size="sm"
          onClick={onBackClick}
          label={intl.get(
            "userInfo.inputs.birthYear.errorModal.backButtonLabel"
          )}
        />
        <Button
          size="sm"
          href="/"
          label={intl.get(
            "userInfo.inputs.birthYear.errorModal.homeButtonLabel"
          )}
        />
      </ButtonGroup>
    </div>
  );

  setSharedModalContent("accounts", birthYearModalContent);
  openModal("accounts");
}

export function closeBirthYearModal() {
  closeModal("accounts");
}

/**
 * Detect if mobile browser is being used
 *
 * @returns {boolean}
 */
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function isPublishMode() {
  return !inIframe();
}

export function getSiteRoot(url) {
  try {
    const parsed = new URL(url);
    return parsed.pathname.startsWith("/content/")
      ? parsed.origin +
          parsed.pathname.split("/").slice(0, 4).join("/") +
          "/home.html"
      : parsed.origin;
  } catch (e) {
    return null;
  }
}

export function getSource() {
  // Source is set on the account patient account buttons component.
  const patientAccountButtons = document.querySelector(
    ".cope-core-patient-account-buttons"
  );

  return patientAccountButtons ? patientAccountButtons.dataset["source"] : ""; // TODO Probably need a better default here.
}

export function getBirthyearThreshold() {
  switch (getSource()) {
    case "norditropin":
      return 18;

    default:
      return 18;
  }
}

export function requiresOptInMarketing() {
  switch (getSource()) {
    case "norditropin":
      return false;

    default:
      return false;
  }
}

/**
 * Checks whether it is "safe" to redirect the user to the given URL.
 * "safe" means it refers to a page on the same domain.
 * @param {string} url
 * @returns {boolean}
 */
export function isUrlSafeForRedirect(url) {
  return getSiteRoot(window.location) === getSiteRoot(url);
}

export function redirect(url) {
  try {
    const currentUrl = new URL(window.location);
    const targetUrl = new URL(url);

    // Preserve WCMMode
    if (
      currentUrl.searchParams.has("wcmmode") &&
      !targetUrl.searchParams.has("wcmmode")
    ) {
      targetUrl.searchParams.append(
        "wcmmode",
        currentUrl.searchParams.get("wcmmode")
      );
    }

    window.location.href = targetUrl.toString();
  } catch (e) {
    window.location.href = url;
  }
}

/**
 * Convert string to slug
 *
 * @param {string} text
 */
export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

/**
 * Smooth scroll to element and focus on it
 *
 * @param {string} id
 */
export function scrollToInput(id, scrollElement) {
  scrollElement = [scrollElement, document.documentElement, document.body].find(
    (x) =>
      x && typeof x.scrollTop === "number" && typeof x.scrollTo === "function"
  );

  const offset = 75;
  const input = scrollElement.querySelector("#" + id);
  if (!input) return;

  const inputGroup = input.closest(".input-group");
  const inputGroupTop =
    inputGroup.getBoundingClientRect().top -
    offset -
    scrollElement.getBoundingClientRect().top;

  const cancel = animate(
    scrollElement.scrollTop,
    inputGroupTop,
    400,
    (x) => {
      scrollElement.scrollTo(0, x);
    },
    () => {
      // Try to focus on element
      input.focus();

      // If that element was not able to be focused, set tabindex and then refocus
      if (document.activeElement !== input) {
        input.tabIndex = -1;
        input.focus();
        // We can hide the outline here because normally this element wouldn't be focusable.
        // We made it focusable so the tab order could be set correctly
        input.style.outline = "none";
      }

      cancelScroll();
    }
  );

  addEventListenerMulti(
    document.querySelectorAll("html, body"),
    "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
    cancelScroll
  );

  function cancelScroll() {
    cancel();
    removeEventListenerMulti(
      document.querySelectorAll("html, body"),
      "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
      cancelScroll
    );
  }
}

/**
 * Scroll to selector or element
 *
 * @param {string} selector
 */
export function scrollTo(selector, scrollElement) {
  scrollElement = [scrollElement, document.documentElement, document.body].find(
    (x) =>
      x && typeof x.scrollTop === "number" && typeof x.scrollTo === "function"
  );

  const offset = 75;
  const el =
    typeof selector === "string"
      ? scrollElement.querySelector(selector)
      : selector;
  const elTop =
    el.getBoundingClientRect().top -
    offset -
    scrollElement.getBoundingClientRect().top;

  const cancel = animate(
    scrollElement.scrollTop,
    elTop,
    400,
    (x) => {
      scrollElement.scrollTo(0, x);
    },
    () => {
      // Try to focus on element
      el.focus();

      // If that element was not able to be focused, set tabindex and then refocus
      if (document.activeElement !== el) {
        el.tabIndex = -1;
        el.focus();
        // We can hide the outline here because normally this element wouldn't be focusable.
        // We made it focusable so the tab order could be set correctly
        el.style.outline = "none";
      }

      cancelScroll();
    }
  );

  addEventListenerMulti(
    document.querySelectorAll("html, body"),
    "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
    cancelScroll
  );

  function cancelScroll() {
    cancel();
    removeEventListenerMulti(
      document.querySelectorAll("html, body"),
      "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
      cancelScroll
    );
  }
}

/**
 * Animate value
 *
 * @param {number} from
 * @param {number} to
 * @param {number} duration
 * @param {function} update
 * @param {function} done
 */
export default function animate(
  from,
  to,
  duration,
  update,
  done = function () {}
) {
  let cancel = false;
  let start = null;
  const change = to - from;

  function easeInOutQuad(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }

  function loop(timestamp) {
    if (cancel) {
      return;
    }

    start = !start ? timestamp : start;
    const progress = timestamp - start;

    update(easeInOutQuad(progress, from, change, duration));

    if (progress < duration) {
      window.requestAnimationFrame(loop);
    } else {
      done();
    }
  }

  window.requestAnimationFrame(loop);

  return function cancelLoop() {
    cancel = true;
  };
}

/**
 * Handle scrolling to error summary or first field with error
 *
 * @param {object} errors
 * @param {boolean} forceScrollToSummary
 */
export function handleErrorScrolling(
  errors,
  scrollElement,
  forceScrollToSummary = false
) {
  if (Object.keys(errors).length > 1 || forceScrollToSummary) {
    scrollTo(".error-summary", scrollElement);
  } else {
    const firstFieldWithError = Object.keys(errors)[0];
    scrollToInput(firstFieldWithError, scrollElement);
  }
}

/**
 * Get query parameter by name
 *
 * @param {string} name
 * @param {string} url
 */
export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/**
 * Return an object with the first error for each field
 *
 * @param {object} errors Error object with all errors for each field
 */
export function firstErrorAll(errors) {
  return Object.keys(errors).reduce((accumulator, field) => {
    accumulator[field] = [errors[field][0]];

    return accumulator;
  }, {});
}

/**
 * Set property on nested object by using `.` delimited string
 *
 * @param {object} object Object to set property on
 * @param {string} property Property to set
 * @param {*} value Value to set property to
 * @returns {object}
 */
export function setObjectProperty(object, property, value) {
  const run = (object, property, value) => {
    const [head, ...rest] = property.split(".");

    !rest.length
      ? (object[head] = value)
      : run(object[head], rest.join("."), value);
  };

  run(object, property, value);

  return object;
}

/**
 * Get object property by string. Allows retrieval of nested properties by using
 * a dot in the property name. Example: `getObjectProperty({foo: {bar: 'baz'}}, 'foo.bar')`
 *
 * @param {object} object
 * @param {string} property
 * @returns {any}
 */
export function getObjectProperty(object, property) {
  return property.split(".").reduce((accumulator, currentValue) => {
    return accumulator ? accumulator[currentValue] : undefined;
  }, object);
}

/**
 * Parse and return server errors
 *
 * @param {object} response
 * @returns {object}
 */
export function parseServerErrors(response, customErrorMessage = () => false) {
  if (response && response.status === 400) {
    const serverErrors = getObjectProperty(response, "parsedBody.errors");
    if (serverErrors) {
      const errors = serverErrors.reduce((accumulator, error) => {
        let errorMessage;
        if (customErrorMessage(error) !== false) {
          errorMessage = customErrorMessage(error);
        } else {
          errorMessage = intl.get(
            `app.validation.${error.code}.${error.field}`
          );
        }
        accumulator[error.field] = accumulator[error.field]
          ? accumulator[error.field].push(errorMessage)
          : [errorMessage];

        return accumulator;
      }, {});

      return errors;
    } else {
      return {};
    }
  } else if (response && response.status === 429) {
    if (
      response.errors[0] &&
      response.errors[0].field &&
      response.errors[0].field === "email"
    ) {
      return {
        email: [intl.get(`app.validation.accountLocked.email`)],
      };
    } else {
      return {
        global: intl.get(`app.validation.accountLocked.rateLimiting`),
      };
    }
  } else {
    return {};
  }
}

/**
 * Add multiple event listeners to elements
 *
 * @param {Element|NodeList} element
 * @param {string} listeners
 * @param {function} callback
 */
export function addEventListenerMulti(element, listeners, callback) {
  function addListeners(el) {
    listeners
      .split(" ")
      .forEach((listener) => el.addEventListener(listener, callback));
  }

  if (NodeList.prototype.isPrototypeOf(element)) {
    [...element].forEach(addListeners);
  } else {
    addListeners(element);
  }
}

/**
 * Remove multiple event listeners from elements
 *
 * @param {Element|NodeList} element
 * @param {string} listeners
 * @param {function} callback
 */
export function removeEventListenerMulti(element, listeners, callback) {
  function removeListeners(el) {
    listeners
      .split(" ")
      .forEach((listener) => el.removeEventListener(listener, callback));
  }

  if (NodeList.prototype.isPrototypeOf(element)) {
    [...element].forEach(removeListeners);
  } else {
    removeListeners(element);
  }
}

export function trackEvent(category, action, label, value) {
  if (
    window.NovoMedLink &&
    window.NovoMedLink.Analytics &&
    typeof window.NovoMedLink.Analytics.trackEvent === "function"
  ) {
    window.NovoMedLink.Analytics.trackEvent(category, action, label, value);
  }
}

export function validateEmail(email) {
  const match = email.match(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/);
  return match && match.length !== 0;
}

export async function getEcid() {
  if (typeof alloy != "undefined") {
    return alloy("getIdentity")
      .then(function (result) {
        return result.identity.ECID;
      })
      .catch(function (error) {
        console.error("Error retrieving ECID:", error);
        return "";
      });
  } else {
    const ECID_PATTERN = /AMCV_(.*)=MCMID\|(.*)/;
    const cookies = document.cookie.split("; ");
    const found = cookies.find((pair) => pair.match(ECID_PATTERN));
    if (found) {
      const [_, key, value] = found.match(ECID_PATTERN);
      return value;
    }
    return "";
  }
}
