import "./style.scss";
import Tooltip from "common/components/partials/tooltip";
import * as React from "react";
import { slugify } from "common/helpers";

export default class RadioInput extends React.Component {
  constructor(props) {
    super(props);
  }

  /**
   * Check if input has errors
   *
   * @returns {boolean}
   */
  hasErrors() {
    return this.props.errors !== undefined && this.props.errors.length;
  }

  /**
   * Event handler for radio button change
   *
   * @param {Object} event
   */
  handleChange = (event) => {
    const { onChange } = this.props;

    if (onChange != null) {
      onChange(event);
      this.forceUpdate();
    }
  };

  render() {
    const {
      name,
      label,
      value,
      options,
      errors,
      required,
      suppressRequiredLabel,
    } = this.props;
    return (
      <div
        className={`cope-core--form--field input-group ${
          this.hasErrors() ? "has-errors" : ""
        }`}
      >
        <fieldset
          role="radiogroup"
          className="radio-fieldset"
          id={name}
          onChange={this.handleChange}
          aria-required={required ? "true" : false}
        >
          <div className="cope-core-text text-headline-5">
            <label
              className={required && !suppressRequiredLabel ? "required" : ""}
            >
              {label}
            </label>
          </div>
          <div className="error-wrap">
            <div className="radio-buttons">
              {options.map((option) => {
                return (
                  <div key={option.value} className="radio-wrap">
                    <input
                      type="radio"
                      id={slugify(`${label} ${option.value}`)}
                      value={option.value}
                      name={name}
                      checked={value === option.value}
                      aria-required={required ? "true" : false}
                      onChange={() => {}} // Suppress React error
                    />
                    <label htmlFor={slugify(`${label} ${option.value}`)}>
                      {option.label}
                    </label>
                    {value === option.value && option.extraControls && (
                      <div className="extra-controls">
                        {option.extraControls}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {this.hasErrors()
              ? errors.map((error) => (
                  <div
                    key={error}
                    className="cope-core-text text-body-1 cope-core-text text-body-1 cope-core--form--error error-message"
                  >
                    <p>{error}</p>
                  </div>
                ))
              : null}
          </div>
        </fieldset>
      </div>
    );
  }
}
